import React, { useEffect, useState } from "react";
import { Card, CardBody, CardExpandToggler } from "./../../components/card/card.jsx";
import Chart from "react-apexcharts";

function Dashboard() {
	function randomNo() {
		return Math.floor(Math.random() * 60) + 30;
	}

	// server chart
	var serverChartData = [
		{
			name: "MEMORY USAGE",
			data: [
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
			],
		},
		{
			name: "CPU USAGE",
			data: [
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
				randomNo(),
			],
		},
	];

	const [statsData, setStatsData] = useState();
	const [serverData, setServerData] = useState();
	const [countryData, setCountryData] = useState();
	const [sourceData, setSourceData] = useState();
	const [sourceChartData, setSourceChartData] = useState();
	const [productData, setProductData] = useState();
	const [activityLogData, setActivityLogData] = useState();
	const [chartOptions, setChartOptions] = useState(getChartOptions());
	const [serverChartOptions, setServerChartOptions] = useState(getServerChartOptions());

	function getChartOptions() {
		var themeColor = getComputedStyle(document.body).getPropertyValue("--bs-theme").trim();
		var themeColorRgb = getComputedStyle(document.body).getPropertyValue("--bs-theme-rgb").trim();

		return {
			bar: {
				colors: [themeColor],
				chart: { sparkline: { enabled: true } },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
			line: {
				colors: [themeColor],
				chart: { sparkline: { enabled: true } },
				stroke: { curve: "straight", width: 2 },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
			pie: {
				colors: ["rgba(" + themeColorRgb + ", 1)", "rgba(" + themeColorRgb + ", .75)", "rgba(" + themeColorRgb + ", .5)"],
				chart: { sparkline: { enabled: true } },
				stroke: { show: false },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
			donut: {
				colors: [
					"rgba(" + themeColorRgb + ", .15)",
					"rgba(" + themeColorRgb + ", .35)",
					"rgba(" + themeColorRgb + ", .55)",
					"rgba(" + themeColorRgb + ", .75)",
					"rgba(" + themeColorRgb + ", .95)",
				],
				chart: { sparkline: { enabled: true } },
				stroke: { show: false },
				tooltip: {
					x: { show: false },
					y: {
						title: {
							formatter: function (seriesName) {
								return "";
							},
						},
					},
				},
			},
		};
	}

	function getServerChartOptions() {
		var borderColor = getComputedStyle(document.body).getPropertyValue("--bs-border-color").trim();
		var bodyColor = getComputedStyle(document.body).getPropertyValue("--bs-body-color").trim();
		var inverseRgb = getComputedStyle(document.body).getPropertyValue("--bs-inverse-rgb").trim();
		var themeColor = getComputedStyle(document.body).getPropertyValue("--bs-theme").trim();
		var themeFont = getComputedStyle(document.body).getPropertyValue("--bs-body-font-family").trim();

		return {
			chart: { toolbar: { show: false } },
			plotOptions: { bar: { horizontal: false, columnWidth: "55%", endingShape: "rounded" } },
			dataLabels: { enabled: false },
			grid: { show: true, borderColor: borderColor },
			stroke: { show: false },
			colors: ["rgba(" + inverseRgb + ", .15)", themeColor],
			legend: { fontFamily: themeFont, labels: { colors: bodyColor } },
			xaxis: {
				categories: [
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
					"Sep",
					"Oct",
					"Nov",
					"Dec",
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
					"Sep",
					"Oct",
					"Nov",
					"Dec",
				],
				labels: { show: false },
				axisBorder: { show: true, color: borderColor, height: 1, width: "100%", offsetX: 0, offsetY: -1 },
				axisTicks: { show: true, borderType: "solid", color: borderColor, height: 6, offsetX: 0, offsetY: 0 },
			},
			yaxis: {
				labels: {
					style: { colors: bodyColor, fontSize: "12px", fontFamily: themeFont, fontWeight: 400, cssClass: "apexcharts-xaxis-label" },
				},
			},
			fill: { opacity: 0.65 },
			tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " thousands";
					},
				},
			},
		};
	}

	useEffect(() => {
		fetch("/assets/data/dashboard/stats.json")
			.then((res) => res.json())
			.then((result) => {
				setStatsData(result);
			});
		fetch("/assets/data/dashboard/server.json")
			.then((res) => res.json())
			.then((result) => {
				setServerData(result);
			});
		fetch("/assets/data/dashboard/country.json")
			.then((res) => res.json())
			.then((result) => {
				setCountryData(result);
			});
		fetch("/assets/data/dashboard/source.json")
			.then((res) => res.json())
			.then((result) => {
				setSourceData(result);
			});
		fetch("/assets/data/dashboard/source-chart.json")
			.then((res) => res.json())
			.then((result) => {
				setSourceChartData(result);
			});
		fetch("/assets/data/dashboard/product.json")
			.then((res) => res.json())
			.then((result) => {
				setProductData(result);
			});
		fetch("/assets/data/dashboard/activity-log.json")
			.then((res) => res.json())
			.then((result) => {
				setActivityLogData(result);
			});
	}, []);

	return (
		<div>
			<div className="row">
				{statsData &&
					statsData.length > 0 &&
					statsData.map((stat, index) => (
						<div className="col-xl-3 col-lg-6" key={index}>
							<Card className="mb-3">
								<CardBody>
									<div className="d-flex fw-bold small mb-3">
										<span className="flex-grow-1">{stat.title}</span>
										<CardExpandToggler />
									</div>
									<div className="row align-items-center mb-2">
										<div className="col-7">
											<h3 className="mb-0">{stat.total}</h3>
										</div>
										<div className="col-5">
											<div className="mt-n2">
												<Chart
													type={stat.chartType}
													height={stat.chartHeight}
													options={chartOptions[stat.chartType]}
													series={stat.chartData}
												/>
											</div>
										</div>
									</div>
									<div className="small text-inverse text-opacity-50 text-truncate">
										{stat.info.length > 0 &&
											stat.info.map((info, index) => (
												<div key={index}>
													<i className={info.icon}></i> {info.text}
												</div>
											))}
									</div>
								</CardBody>
							</Card>
						</div>
					))}

				<div className="col-xl-12">
					<Card className="mb-3">
						<CardBody>
							<div className="d-flex fw-bold small mb-3">
								<span className="flex-grow-1">Ultimos Clientes Registrados</span>
								<CardExpandToggler />
							</div>
							<div className="table-responsive">
								<table className="table table-striped table-borderless mb-2px small text-nowrap">
									<tbody>
										{activityLogData && activityLogData.length > 0 ? (
											activityLogData.map((log, index) => (
												<tr key={index}>
													<td>
														<span className="d-flex align-items-center">
															<i
																className={
																	"bi bi-circle-fill fs-6px me-2 " +
																	(log.highlight ? "text-theme" : "text-inverse text-opacity-30")
																}
															></i>
															{log.title}
														</span>
													</td>
													<td>
														<small>{log.time}</small>
													</td>
													<td>
														<span
															className={
																"badge d-block rounded-0 pt-5px w-70px " +
																(log.highlight ? "bg-theme text-theme-900" : "bg-inverse bg-opacity-25")
															}
															style={{ minHeight: "18px" }}
														>
															Activo
														</span>
													</td>
													<td>
														<a href="#/" className="text-decoration-none text-inverse">
															<i className="bi bi-check"></i>
														</a>
													</td>
												</tr>
											))
										) : (
											<tr>
												<td colSpan="4">No records found</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;
