import React, { useEffect, useRef, useState } from "react";
import { Card } from "./../../components/card/card.jsx";
import { Map as OLMap, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import { Icon, Style } from "ol/style";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";

function PagesMap() {
	const mapRef = useRef();
	const [map, setMap] = useState(null);
	const [markers, setMarkers] = useState([
		{ id: 1, position: fromLonLat([-84.075, 9.993]), type: "centro de acopio", name: "San José", visible: false },
		{ id: 2, position: fromLonLat([-85.622, 10.574]), type: "centro de canje", name: "Liberia", visible: false },
		{ id: 3, position: fromLonLat([-83.753, 9.633]), type: "camión recolector", name: "Limón", visible: false },
		{ id: 4, position: fromLonLat([-84.192, 10.014]), type: "centro de acopio", name: "Heredia", visible: false },
		{ id: 5, position: fromLonLat([-84.233, 9.998]), type: "camión recolector", name: "Alajuela", visible: false },
		{ id: 6, position: fromLonLat([-85.437, 10.473]), type: "centro de acopio", name: "Cañas", visible: false },
		{ id: 7, position: fromLonLat([-85.66, 10.626]), type: "camión recolector", name: "Tilarán", visible: false },
		{ id: 8, position: fromLonLat([-83.909, 9.421]), type: "centro de canje", name: "Quepos", visible: false },
		{ id: 9, position: fromLonLat([-83.052, 9.862]), type: "centro de acopio", name: "Turrialba", visible: false },
		{ id: 10, position: fromLonLat([-83.321, 9.977]), type: "centro de canje", name: "Cartago", visible: false },
		{ id: 11, position: fromLonLat([-84.616, 9.867]), type: "camión recolector", name: "Puntarenas", visible: false },
		{ id: 12, position: fromLonLat([-85.842, 10.848]), type: "centro de acopio", name: "Santa Cruz", visible: false },
		{ id: 13, position: fromLonLat([-85.355, 10.954]), type: "centro de canje", name: "Nicoya", visible: false },
		{ id: 14, position: fromLonLat([-84.828, 9.576]), type: "camión recolector", name: "Jacó", visible: false },
		{ id: 15, position: fromLonLat([-84.44, 9.99]), type: "centro de acopio", name: "Grecia", visible: false },
	]);
	const [vectorLayer, setVectorLayer] = useState(null); // nuevo estado para la capa de vector

	useEffect(() => {
		const initialMap = new OLMap({
			target: mapRef.current,
			layers: [
				new TileLayer({
					source: new OSM(),
				}),
			],
			view: new View({
				center: fromLonLat([-84.075, 9.993]), // Coordenadas para centrar el mapa en Costa Rica
				zoom: 7,
			}),
		});
		const newVectorLayer = new VectorLayer({
			source: new VectorSource(),
		});
		initialMap.addLayer(newVectorLayer);
		setMap(initialMap);
		setVectorLayer(newVectorLayer);

		return () => initialMap.setTarget(undefined);
	}, []);

	useEffect(() => {
		if (!map || !vectorLayer) return;

		vectorLayer.getSource().clear(); // Limpia los marcadores antiguos antes de agregar nuevos

		markers
			.filter((marker) => marker.visible)
			.forEach((marker) => {
				const markerFeature = new Feature({
					geometry: new Point(marker.position),
				});
				markerFeature.setStyle(
					new Style({
						image: new Icon({
							anchor: [0.5, 46],
							anchorXUnits: "fraction",
							anchorYUnits: "pixels",
							src: "https://openlayers.org/en/latest/examples/data/icon.png",
						}),
					})
				);
				vectorLayer.getSource().addFeature(markerFeature);
			});
	}, [map, markers, vectorLayer]);

	const handleMarkerTypeChange = (event) => {
		const selectedType = event.target.value;
		setMarkers(
			markers.map((marker) => ({
				...marker,
				visible: marker.type === selectedType || selectedType === "all",
			}))
		);
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Mapa de Costa Rica</h1>
				</div>

				<div className="ms-auto">
					<select onChange={handleMarkerTypeChange} className="form-select">
						<option value="all">Mostrar todos</option>
						<option value="centro de acopio">Centros de Acopio</option>
						<option value="centro de canje">Centros de Canje</option>
						<option value="camión recolector">Camiones Recolectores</option>
					</select>
				</div>
			</div>

			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="mapTab">
						<div ref={mapRef} style={{ height: "700px", width: "100%" }}></div>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default PagesMap;
