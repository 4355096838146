import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { apiUrl } from "../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function PagesRegister() {
	const location = useLocation();
	const navigate = useNavigate();
	const type = location.state?.type || "";
	const data = location.state?.data || [];
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [id, setId] = useState("");
	const [identification, setIdentification] = useState("");
	const [type2, setType2] = useState("");

	useEffect(() => {
		if (type === "edit" && data) {
			setName(data.name || "");
			setEmail(data.email || "");
			setPhone(data.phone || "");
			setPassword(data.password || "");
			setId(data.id || "");
			setIdentification(data.identification || "");
		}
	}, [type, data]);

	const validateForm = () => {
		if (!name || !email || !phone || !password || !identification) {
			Toast.fire({
				icon: "error",
				title: "Todos los campos son obligatorios",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleSubmit = async (event, action = null) => {
		event.preventDefault();
		if (!validateForm()) return;
		try {
			console.log("TYPE2", type2);
			const response = await fetch(apiUrl + "/actions", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					action: type === "add" ? "create" : action === null ? "update" : action,
					table: "adminUsers",
					data: {
						name,
						email,
						phone,
						password,
						identification,
						id,
					},
				}),
			});
			const responseData = await response.json();
			console.log("RESP", responseData);
			if (responseData.ok) {
				Toast.fire({
					icon: "success",
					title: responseData.message,
					background: "#1e2836",
					color: "#fff",
				});
				window.history.back();
			} else {
				Toast.fire({
					icon: "error",
					title: response.message,
					color: "#fff",
					background: "#1e2836",
				});
				throw new Error(responseData.message || "Error al enviar los datos");
			}
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: error.message,
				background: "#1e2836",
				color: "#fff",
			});
		}
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<h1 className="text-center">{type === "add" ? "Agregar Administrador" : "Actualizar Administrador"}</h1>
				<div className="mb-3">
					<label className="form-label">
						Nombre <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="Pedro Navajas"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Correo Electrónico <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="correo@electronico.com"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Teléfono <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="88884444"
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Contraseña <span className="text-danger">*</span>
					</label>
					<input
						type="password"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="N@vajas2024@"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Identificación <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="Identificación"
						value={identification}
						onChange={(e) => setIdentification(e.target.value)}
					/>
				</div>
				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{type === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/adminUsers")} className="btn btn-outline-theme btn-lg">
						Atras
					</button>

					<button
						onClick={(event) => {
							setType2("delete");
							handleSubmit(event, "delete");
						}}
						className="btn btn-outline-danger btn-lg"
					>
						Borrar
					</button>
				</div>
			</form>
		</div>
	);
}

export default PagesRegister;
