import React from "react";
import App from "./../app.jsx";
import { Navigate } from "react-router-dom";

import Home from "./../pages/home/home.js";
import Dashboard from "./../pages/dashboard/dashboard.js";
import Users from "./../pages/pages/users.js";
import AdminUsers from "./../pages/pages/adminUsers.js";
import AdminUsersDetails from "./../pages/pages/adminUsersDetails.js";
import UsersDetails from "./../pages/pages/usersDetails.js";
import AfiliatesDetails from "../pages/pages/afiliatesDetails.js";
import Afiliates from "../pages/pages/afiliates.js";
import Complaints from "../pages/pages/complaints.js";
import Recycles from "../pages/pages/recycles.js";
import Transactions from "../pages/pages/transactions.js";
import Products from "../pages/pages/products.js";
import ProductsDetails from "../pages/pages/productsDetails.js";
import Map from "./../pages/pages/map.js";
import PagesError from "./../pages/pages/error.js";

const AppRoute = [
	{
		path: "*",
		element: <App />,
		children: [
			{ path: "", element: <Navigate to="/dashboard" /> },
			{ path: "dashboard", element: <Dashboard /> },
			{ path: "afiliates", element: <Afiliates /> },
			{ path: "complaints", element: <Complaints /> },
			{ path: "recycles", element: <Recycles /> },
			{ path: "transactions", element: <Transactions /> },
			{ path: "products", element: <Products /> },
			{ path: "productsDetails", element: <ProductsDetails /> },
			{ path: "map", element: <Map /> },
			{ path: "users", element: <Users /> },
			{ path: "adminUsers", element: <AdminUsers /> },
			{ path: "adminUsersDetails", element: <AdminUsersDetails /> },
			{ path: "usersDetails", element: <UsersDetails /> },
			{ path: "afiliatesDetails", element: <AfiliatesDetails /> },
			{ path: "home", element: <Home /> },
			{ path: "*", element: <PagesError /> },
		],
	},
];

export default AppRoute;
