import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { apiUrl } from "../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function AfiliatesDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const type = location.state?.type || "";
	const data = location.state?.data || [];
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState("");
	const [status, setStatus] = useState("");
	const [image, setImage] = useState("");
	const [id, setId] = useState("");

	useEffect(() => {
		if (type === "edit" && data) {
			setImage(data.image || "");
			setName(data.name || "");
			setDescription(data.description || "");
			setPrice(data.price || "");
			setStatus(data.status || "");
			setId(data.id || "");
		}
	}, [type, data]);

	const validateForm = () => {
		if (!name || !name || !name || !description || !price || !status) {
			Toast.fire({
				icon: "error",
				title: "Todos los campos son obligatorios",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleSubmit = async (event, action = null) => {
		event.preventDefault();
		if (!validateForm()) return;
		try {
			const response = await fetch(apiUrl + "/actions", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					action: type === "add" ? "create" : action === null ? "update" : action,
					table: "products",
					data: {
						id,
						image: image,
						name,
						description,
						price,
						status,
					},
				}),
			});
			const responseData = await response.json();
			console.log("RESP", responseData);
			if (responseData.ok) {
				Toast.fire({
					icon: "success",
					title: responseData.message,
					background: "#1e2836",
					color: "#fff",
				});
				window.history.back();
			} else {
				Toast.fire({
					icon: "error",
					title: response.message,
					color: "#fff",
					background: "#1e2836",
				});
				throw new Error(responseData.message || "Error al enviar los datos");
			}
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: error.message,
				background: "#1e2836",
				color: "#fff",
			});
		}
	};

	const handleImageUpload = (file) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			setImage(reader.result);
		};
		reader.readAsDataURL(file);
	};

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<h1 className="text-center">{type === "add" ? "Agregar Producto" : "Actualizar Producto"}</h1>
				<div className="mb-3">
					<label className="form-label">
						Imagen <span className="text-danger">*</span>
					</label>
					<div className="input-group">
						<input
							type="file"
							className="form-control form-control-lg bg-white bg-opacity-5"
							accept="image/*"
							onChange={(e) => handleImageUpload(e.target.files[0])}
						/>
						{image && (
							<div className="input-group-append">
								<span className="input-group-text">
									<img src={image} alt="Preview" style={{ maxWidth: "100px", maxHeight: "100px" }} />
								</span>
							</div>
						)}
					</div>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Nombre <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="Compostera Grande"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Descripción<span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="Esto es un..."
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Precio <span className="text-danger">*</span>
					</label>
					<input
						type="text"
						className="form-control form-control-lg bg-white bg-opacity-5"
						placeholder="1500"
						value={price}
						onChange={(e) => setPrice(e.target.value)}
					/>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Estado <span className="text-danger">*</span>
					</label>
					<div className="form-check form-switch">
						<input
							className="form-check-input"
							type="checkbox"
							checked={status === "Activo"}
							onChange={(e) => setStatus(e.target.checked ? "Activo" : "Inactivo")}
						/>
						<label className="form-check-label">{status === "Activo" ? "Activo" : "Inactivo"}</label>
					</div>
				</div>

				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{type === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/products")} className="btn btn-outline-theme btn-lg">
						Atras
					</button>

					<button
						onClick={(event) => {
							handleSubmit(event, "delete");
						}}
						className="btn btn-outline-danger btn-lg">
						Borrar
					</button>
				</div>
			</form>
		</div>
	);
}

export default AfiliatesDetails;
